 <template>
  <div class="d-flex justify-center">
    <div
      class="d-flex flex-column justify-center"
      style="max-width: 1000px; width: 100%"
    >
      <div class="d-flex flex-column w-100 position-relative">
        <v-img
          :src="
            profile._id == company._id
              ? profile.bgImage
                ? profile.bgImage
                : require('@/assets/background0.png')
              : company && company.bgImage
              ? company.bgImage
              : require('@/assets/background0.png')
          "
          width="100%"
          style="flex-grow: 1"
          :aspect-ratio="$vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455"
          v-if="company"
        />
        <div
          style="padding-left: 300px; background-color: #eee4ff"
          class="hidden-sm-and-down"
        >
          <v-tabs
            background-color="#eee4ff"
            @change="onSelected"
            v-model="selectedPage"
          >
            <v-tab v-for="item in menus" :key="item.title">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
        <div
          style="background-color: #eee4ff"
          class="hidden-md-and-up"
          :style="
            $vuetify.breakpoint.xs && profile._id != volunteer._id
              ? 'margin-top: 100px;'
              : 'margin-top: 60px;'
          "
          v-if="company"
        >
          <v-tabs
            background-color="#eee4ff"
            @change="onSelected"
            v-model="selectedPage"
            grow
          >
            <v-tab v-for="item in menus" :key="item.title">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
        <div :class="avatarClasses" :style="avatarStyles" @click="onEditName">
            <img 
              v-if="company && (profile.midThumbnail || profile.image)" 
              :src="
                company._id == profile._id
                  ? profile.midThumbnail
                    ? profile.midThumbnail
                    : profile.image
                    ? profile.image
                    : placeholderImage
                  : company.midThumbnail
                  ? company.midThumbnail
                  : company.image
                  ? company.image
                  : placeholderImage
              " 
              :alt="name" 
              class="avatar-img"
            />
          <span v-else>{{ profile.midThumbnail }}</span>
        </div>
        <div @click="onEditName" class="cursor-pointer">
          <agency-avatar
            v-if="company"
            :img="
              company._id == profile._id
                ? profile.midThumbnail
                  ? profile.midThumbnail
                  : profile.image
                  ? profile.image
                  : placeholderImage
                : company.midThumbnail
                ? company.midThumbnail
                : company.image
                ? company.image
                : placeholderImage
            "
            :size="$vuetify.breakpoint.xs ? 140 : 180"
            :name="company.name"
            :editable="false"
            :onImageSelected="onImageSelected"
            style="position: absolute;"
            :style="
              $vuetify.breakpoint.xs && profile._id != company._id
                ? 'bottom: 110px; left: 50%; transform: translate(-50%,0)'
                : $vuetify.breakpoint.xs && profile._id == company._id
                ? 'bottom: 60px; left: 50%; transform: translate(-50%,0)'
                : $vuetify.breakpoint.sm
                ? 'bottom: 60px; left: 40px;'
                : 'bottom: 0px; left: 40px;'
            "
            class="d-none"
          />

        </div>
        <circle-icon-button
          text="Edit Background"
          icon="mdi-image-edit"
          style="position: absolute; top: 20px; right: 20px;"
          :onClicked="onChangeBackground"
          v-if="company && company._id == profile._id"
        />
        <div
          style="position: absolute;bottom: 60px;"
          :style="
            (company &&
            company._id != profile._id &&
            !(!getFanned && profileType == 'Agency') &&
            profileType != 'Admin'
              ? 'width: 298px;'
              : 'width: 160px;') +
              ($vuetify.breakpoint.xs
                ? 'left: 50%; transform: translate(-50%,0)'
                : 'right: 30px;')
          "
        >
          <v-btn
            @click="becomeFan"
            color="#B5E539"
            v-if="
              company &&
                company._id != profile._id &&
                !(!getFanned && profileType == 'Agency') &&
                profileType != 'Admin'
            "
            :loading="becomingFan"
          >
            {{
              profileType == "Company"
                ? getFanned
                  ? "Unfollow"
                  : "Follow"
                : "Fan"
            }}
            <v-icon class="ml-2" size="16"
              >{{ getFanned ? "mdi-account-check" : "mdi-account-plus" }}
            </v-icon>
          </v-btn>
          <v-btn
            @click="goToMessage"
            color="#B5E539"
            class="ml-5"
            v-if="company && company._id != profile._id"
            >Message <v-icon class="ml-2" size="16">mdi-message</v-icon></v-btn
          >
        </div>
      </div>
      <router-view />
    </div>
    <company-profile-edit-background
      :dialog="bgDialog"
      :onClose="closeBGDialog"
    />
    <company-profile-edit-name
      :dialog="editNameDialog"
      :onClose="onCloseEditDialog"
      v-if="editNameDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
import { mapActions, mapGetters } from "vuex";
import CircleIconButton from "@/components/common/CircleIconButton.vue";
import CompanyProfileEditName from "../../company/profile/edit/CompanyProfileEditName.vue";
import CompanyProfileEditBackground from "../../company/profile/edit/CompanyProfileEditBackground.vue";
export default {
  components: {
    AgencyAvatar,
    CircleIconButton,
    CompanyProfileEditName,
    CompanyProfileEditBackground
  },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      selectedImage: null,
      selectedPage: 0,
      menus: [
        {
          title: "Events",
          router: "company-profile-events",
          path: "/events"
        },
        {
          title: "About",
          router: "company-profile-about",
          path: "/needs"
        },
        {
          title: "Needs",
          router: "company-profile-needs",
          path: "/needs"
        },
        {
          title: "Agencies",
          router: "company-profile-agencies",
          path: "/agencies"
        }
      ],
      placeholderImage: require("@/assets/default.jpg"),
      company: null,
      bgDialog: false,
      becomingFan: false,
      editNameDialog: false,
      profileColour:{},
      avatarBorderColor: 'none',
      avatarBorderStyle: 'none',
      name: ''
    };
  },
  methods: {
    ...mapActions("volunteer", {
      getVolunteer: "getVolunteer",
      followVolunteer: "followVolunteer"
    }),
    loadData() {
      this.loading = true;
      if (this.$route.params.id) {
        this.getVolunteer({ _id: this.$route.params.id })
          .then(res => {
            this.loading = false;
            this.company = res;
            this.avatarBorderColor = res.avatarColour || 'none';
            this.avatarBorderStyle = res.avatarColour ? 'solid' : 'none';
          })
          .catch(error => {
            this.loading = false;
            if (error.response != undefined && error.response.status === 401) {
              this.tokenDialog = true;
            } else {
              console.log(error.response ? error.response.data.message : error);
              this.$router.replace({ name: "404" });
            }
          });
      } else {
        if (this.profileType == "Agency") {
          this.$router.replace({
            name: "agency-profile-details",
            params: { id: this.profile._id }
          });
        } else if (this.profileType == "Volunteer") {
          this.$router.replace({
            name: "volunteer-profile-details",
            params: { id: this.profile._id }
          });
        } else if (this.profileType == "Company") {
          this.$router.replace({
            name: "company-profile-details",
            params: { id: this.profile._id }
          });
        }
      }
    },
    onImageSelected(image) {
      this.selectedImage = image;
    },
    checkSelected(item) {
      return item === this.menus[this.selectedPage];
    },
    onSelected(index) {
      if (index != undefined) {
        if (this.$route.name && this.$route.name != this.menus[index].router) {
          this.$router.push({ name: this.menus[index].router });
        }
      } else {
        setTimeout(() => {
          for (var i = 0; i < this.menus.length; i++) {
            if (this.menus[i].router == this.$route.name) {
              this.selectedPage = i;
              break;
            }
          }
        }, 300);
      }
    },
    onChangeBackground() {
      this.bgDialog = true;
    },
    closeBGDialog() {
      this.bgDialog = false;
      this.company.bgImage = this.profile.bgImage;
    },
    goToMessage() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$router.push({
          name: "mobile-chat-room",
          query: { _id: this.volunteer._id, type: "Volunteer" }
        });
      } else {
        this.$router.push({
          name: "chat-details",
          query: { _id: this.volunteer._id, type: "Volunteer" }
        });
      }
    },
    becomeFan() {
      if (this.profileType == "Volunteer") {
        let fanned = this.getFanned;
        this.becomingFan = true;
        this.followVolunteer({ followed: !fanned, user: this.volunteer._id })
          .then(() => {
            this.becomingFan = false;
          })
          .catch(error => {
            this.becomingFan = false;
            console.log(error.response.data.message);
          });
      }
    },
    onEditName() {
      if (this.profile._id == this.$route.params.id) this.editNameDialog = true;
    },
    onCloseEditDialog() {
      this.editNameDialog = false;
    }
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType"
    }),
    getFanned() {
      if (this.profileType == "Volunteer") {
        if (!this.profile.followers) return false;
        if (this.profile.followers.includes(this.volunteer._id)) return true;
      } else if (this.profileType == "Agency" || this.profileType == "Company") {
        if (!this.profile.fanned) return false;
        if (this.profile.fanned.includes(this.volunteer?._id || this.company._id)) return true;
      } else if (this.profileType == "Company") {
        if (!this.profile.fanned) return false;
        if (this.profile.fanned.includes(this.volunteer._id)) return true;
      }
      return false;
    },
    avatarStyles() {
      return {
        width: '178px',
        height: '178px',
        borderColor: this.avatarBorderColor,
        borderWidth: '4px',
        borderStyle: this.avatarBorderStyle,
        borderRadius: '50%',
        objectFit: 'cover',
        'z-index': '1 !important',
        'margin-top': '-178px',
        'margin-left': '39px'
      };
    },
    avatarClasses() {
      return ['avatar', this.profile.midThumbnail ? 'has-image' : 'no-image'];
    }
  },
  watch: {
    "$route.params.id": function() {
      this.loadData();
    },
    "$route.name": function() {
      this.selectedPage = -1;
      for (var i = 0; i < this.menus.length; i++) {
        if (this.menus[i].router == this.$route.name) {
          this.selectedPage = i;
          break;
        }
      }
    }
  },
  mounted() {
    this.selectedPage = -1;
    for (var i = 0; i < this.menus.length; i++) {
      if (this.menus[i].router == this.$route.name) {
        this.selectedPage = i;
        break;
      }
    }
    this.loadData();
  }
};
</script>
<style scoped>
@media (max-width: 480px) {
  div :deep(.v-tab) {
    min-width: 48px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
  }
}


.avatarClasses .avatarStyles {
    border-radius: 50%;
    object-fit: cover;
    width: 99px;
    height: 99px;
    cursor: pointer;
}

.avatar-img {
    border-radius: 50%;
    height: 170px;
    width: 170px;
    cursor: pointer;
}
</style>
